<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { IIconProps } from '../../base/ApzIcon/ApzIcon.vue';
import Logo from '~/assets/svg/logo.svg';
import {
  ref,
  useConfigStore,
  useI18n,
  useNavigation,
  useAuth,
  useFooterConfig,
  useTracker,
  computed,
  useRouteBaseName,
  useRoute,
  navigateSignIn,
} from '#imports';

interface INavbarProps {
  alternate?: boolean;
  showQuickSettings?: boolean;
  showNavigationLinks?: boolean;
  showAuth?: boolean;
  alternateOnScroll?: boolean;
}

const props = withDefaults(defineProps<INavbarProps>(), {
  alternate: false,
  showQuickSettings: true,
  showNavigationLinks: true,
  showAuth: true,
});

const auth = useAuth();
const config = useConfigStore();
const route = useRoute();
const getRouteBaseName = useRouteBaseName();
const img = useImage();

const { localeProperties } = useI18n();
const { storeLinks } = useFooterConfig();
const { navigationPath, navigate } = useNavigation();
const { jitsuEvent } = useTracker();

const links = [
  {
    text: 'global.home',
    path: navigationPath('/'),
    event: 'user-click-button',
    params: { event_name: 'navbar-home-click', object_name: 'navbar', object_parameter: 'home' },
  },
  {
    text: 'global.flight',
    path: navigationPath('/flight'),
    event: 'user-click-button',
    params: { event_name: 'navbar-flight-click', object_name: 'navbar', object_parameter: 'flight' },
  },
  {
    text: 'global.hotel',
    path: navigationPath('/hotel'),
    event: 'user-click-button',
    params: { event_name: 'navbar-hotel-click', object_name: 'navbar', object_parameter: 'hotel' },
  },
  {
    text: 'global.promo',
    path: navigationPath('/promo'),
    event: 'user-click-button',
    params: { event_name: 'navbar-promo-click', object_name: 'navbar', object_parameter: 'promo' },
  },
  {
    text: 'global.orders',
    path: navigationPath('/order'),
    event: 'user-click-button',
    params: { event_name: 'navbar-orders-click', object_name: 'navbar', object_parameter: 'order' },
  },
];

const showLanguagePicker = ref(false);
const showCurrencyPicker = ref(false);
const showLoginForm = ref(false);
const alternateMode = ref(props.alternate);

const { y: scrollY } = useWindowScroll();

const showLoginFormModal = computed(() => showLoginForm.value && !auth.loggedIn);

const accountMenuItems = computed(() => [
  {
    id: 'order',
    link: '/account/order',
    icon: ['far', 'calendar-check'],
    text: 'orders.navbartitle',
  },
  {
    id: 'profile',
    link: '/account/profile',
    icon: ['far', 'user-circle'],
    text: 'global.profile',
  },
  {
    id: 'changePassword',
    link: '/account/password',
    icon: 'key',
    text: 'profile.changepassword',
  },
  {
    id: 'contactList',
    link: '/account/contact',
    icon: 'list-ul',
    text: 'profile.contactlist',
  },
  {
    id: 'passengerList',
    link: '/account/passenger',
    icon: 'list-alt',
    text: 'global.passengerlist',
  },
  {
    id: 'logout',
    icon: 'sign-out',
    text: 'global.signout',
    link: '',
  },
]);

watch(
  scrollY,
  (y) => {
    if (props.alternateOnScroll) {
      alternateMode.value = y > 0;
    }
  },
  {
    immediate: true,
  },
);

function onShowCurrencyList() {
  jitsuEvent('ui-currency-expand');
  jitsuEvent('user-click-button', {
    event_name: 'navbar-currency-expand',
    object_name: 'navbar',
    object_parameter: 'currency-expand',
  });

  showCurrencyPicker.value = true;
}

function onClickLogo() {
  jitsuEvent('user-click-button', {
    event_name: 'airpaz-logo-click',
    object_name: 'navbar',
    object_parameter: 'logo',
  });
}

function onClickNavbar(eventName: string, params: { [key: string]: string | number | object }) {
  jitsuEvent(eventName, params);
}

function onClickLanguage() {
  jitsuEvent('user-click-button', {
    event_name: 'navbar-language-expand',
    object_name: 'navbar',
    object_parameter: 'language-expand',
  });
  showLanguagePicker.value = true;
}

function onClickDownloadbanner({ event, eventNavbar }: { event: string; eventNavbar: string }) {
  jitsuEvent('user-click-button', {
    event_name: eventNavbar,
    object_name: 'navbar',
    object_parameter: event,
  });
}

function onClickLogin() {
  jitsuEvent('user-click-button', {
    event_name: 'navbar-login-click',
    object_name: 'navbar',
    object_parameter: 'sign-in',
  });

  const routeBaseName = getRouteBaseName(route);
  if (routeBaseName !== 'index') {
    const shouldDisableLoginUrl = Boolean(
      routeBaseName &&
        ['signup-verify', 'signup-setup-verify', 'reset', 'reset-verify', 'reset-pass-id'].includes(routeBaseName),
    );

    navigateSignIn(undefined, shouldDisableLoginUrl);
  } else {
    showLoginForm.value = true;
  }
}

function onClickRegister() {
  jitsuEvent('user-click-button', {
    object_name: 'navbar',
    object_parameter: 'register',
  });

  navigate({ path: '/signup' });
}
</script>

<template>
  <header
    role="navigation"
    class="h-[67px]"
    :class="[alternateMode ? 'bg-white shadow-md' : 'bg-primary-alt', $attrs.class]"
  >
    <div :class="[{ 'justify-between': !showNavigationLinks }, 'relative container flex h-full']">
      <NuxtLink
        :to="navigationPath('/')"
        external
        no-rel
        data-testid="navbar-airpaz-logo"
        class="flex items-center"
        @click="onClickLogo()"
      >
        <Logo
          :class="[alternateMode ? 'text-primary' : 'text-white']"
          width="108"
        />
      </NuxtLink>

      <slot
        v-if="showNavigationLinks"
        name="nav"
      >
        <nav class="flex flex-1 items-center gap-x-10 ml-30">
          <NuxtLink
            v-for="link in links"
            :key="link.text"
            :to="link.path"
            :data-testid="`navbar-${link.text}-textButton`"
            class="inline-block text-base font-bold p-10 group"
            :class="[alternateMode ? 'text-gray-dark' : 'text-white']"
            external
            no-rel
            @click="onClickNavbar(link.event, link.params)"
          >
            <div
              class="relative after:block after:w-full after:h-[2px] after:bg-transparent after:absolute after:top-25 after:left-0"
              :class="[
                alternateMode
                  ? 'group-[.router-link-exact-active]:text-primary group-[.router-link-exact-active]:after:bg-primary group-hover:after:bg-primary'
                  : 'opacity-80 group-[.router-link-exact-active]:opacity-100 group-[.router-link-exact-active]:text-white group-[.router-link-exact-active]:after:bg-white group-hover:after:bg-white',
              ]"
            >
              {{ $t(link.text) }}
            </div>
          </NuxtLink>
        </nav>
      </slot>

      <div class="flex items-center gap-x-10">
        <template v-if="showQuickSettings">
          <div
            data-testid="navbar-country-picker"
            class="text-base font-bold px-10 py-5 cursor-pointer rounded"
            :class="[alternateMode ? 'hover:bg-whiter' : 'hover:bg-primary-light']"
            @click="onClickLanguage"
          >
            <SharedCountryFlag
              :code="localeProperties.flagCode"
              width="20"
              height="15"
              class="drop-shadow-lg"
            />
          </div>

          <div
            v-if="!auth.user?.isAgent"
            data-testid="navbar-currency-picker"
            class="text-base font-bold px-10 py-5 cursor-pointer rounded"
            :class="[alternateMode ? 'hover:bg-whiter' : 'text-white hover:bg-primary-light']"
            @click="onShowCurrencyList"
          >
            {{ config.currency }}
          </div>
        </template>

        <NuxtLink
          :to="navigationPath('/contact')"
          target="_blank"
          external
          no-rel
          data-testid="navbar-help-textButton"
          class="text-base font-bold px-10 py-5 cursor-pointer rounded"
          :class="[alternateMode ? 'hover:bg-whiter' : 'text-white hover:bg-primary-light']"
          @click="
            jitsuEvent('user-click-button', {
              event_name: 'navbar-help-click',
              object_name: 'navbar',
              object_parameter: 'help',
            })
          "
        >
          {{ $t('global.help') }}
        </NuxtLink>

        <Popover class="relative">
          <PopoverButton
            data-testid="navbar-downloadApp-textButton"
            class="text-base font-bold px-10 py-5 cursor-pointer rounded focus:outline-none"
            :class="[alternateMode ? 'hover:bg-whiter' : 'text-white hover:bg-primary-light']"
          >
            {{ $t('global.downloadapp2') }}
          </PopoverButton>

          <PopoverPanel class="absolute left-1/2 -translate-x-1/2 mt-5 bg-white rounded shadow-lg p-15 z-[60]">
            <p class="font-bold text-small text-gray-darkest">{{ $t('global.downloadappheadline') }}</p>

            <NuxtLink
              v-for="store in storeLinks"
              :key="store.img.src"
              :to="store.link"
              target="_blank"
              rel="nofollow"
              class="block my-10 first:mt-0 last:mb-0 w-[152px] h-[46px]"
              @click="onClickDownloadbanner({ event: store.event, eventNavbar: store.eventNavbar })"
            >
              <NuxtImg
                :src="'/img/' + store.img.src"
                :alt="store.alt"
                width="152"
                height="46"
                loading="lazy"
                sizes="lg:304px"
                :placeholder="
                  useDevice().isCrawler
                    ? undefined
                    : img('/img/' + store.img.fallback, {
                        sizes: 'lg:304px',
                        modifiers: {
                          format: 'webp',
                          width: 152,
                          height: 46,
                        },
                      })
                "
              />
            </NuxtLink>
          </PopoverPanel>
        </Popover>

        <template v-if="showAuth">
          <template v-if="!auth.loggedIn">
            <NuxtLink
              v-if="getRouteBaseName(route) !== 'login'"
              data-testid="navbar-login-textButton"
              class="flex items-center text-base font-bold px-10 py-5 cursor-pointer rounded"
              :class="[alternateMode ? 'hover:bg-whiter' : 'text-white hover:bg-primary-light']"
              @click="onClickLogin"
            >
              <ApzIcon
                icon="user-circle"
                class="mr-5"
              />
              <span>{{ $t('global.signinnew') }}</span>
            </NuxtLink>

            <NuxtLink
              v-if="getRouteBaseName(route) !== 'signup'"
              data-testid="home-register-button"
              class="flex items-center text-white font-bold px-10 py-5 cursor-pointer rounded"
              :class="[alternateMode ? 'bg-primary' : 'bg-whiter bg-opacity-20']"
              @click="onClickRegister"
            >
              <span>{{ $t('global.register') }}</span>
            </NuxtLink>
          </template>

          <ApzPopover
            v-else
            position="bottom"
            variant="clear"
            method="click"
            class="z-[60]"
          >
            <template #head>
              <div
                class="flex items-center text-base font-bold px-10 py-5 cursor-pointer rounded"
                :class="[alternateMode ? 'hover:bg-whiter' : 'text-white hover:bg-primary-light']"
              >
                <ApzIcon
                  icon="user-circle"
                  class="mr-5"
                />
                <span>{{ $t('global.hi') + ' ' + auth.user!.name }}</span>
              </div>
            </template>

            <template #panel>
              <div class="flex flex-col rounded p-10 min-w-[220px] shadow-lg">
                <ApzButton
                  v-for="item in accountMenuItems"
                  :key="item.id"
                  variant="clear"
                  :tag="item.id !== 'logout' ? 'nuxt-link' : 'button'"
                  :to="navigationPath({ path: item.link })"
                  external
                  no-rel
                  :class="[
                    'flex gap-x-10 items-center !justify-start p-10 rounded hover:bg-gray-dark hover:bg-opacity-20',
                    item.id === 'logout' && 'text-primary',
                  ]"
                  @click="
                    () => {
                      if (item.id === 'logout') auth.signOut();
                    }
                  "
                >
                  <ApzIcon :icon="(item.icon as IIconProps['icon'])" />

                  {{ $t(item.text) }}
                </ApzButton>
              </div>
            </template>
          </ApzPopover>
        </template>
      </div>
    </div>
  </header>

  <ApzModal
    v-model="showLanguagePicker"
    :title="$t('global.changelanguage')"
    :animated="false"
    class="!max-h-[730px] h-full my-[28px] overflow-hidden"
  >
    <LazyAppNavbarLanguagePicker />
  </ApzModal>

  <ApzModal
    v-model="showCurrencyPicker"
    :title="$t('global.changecurrency')"
    :animated="false"
    class="!max-h-[725px] h-full !max-w-[1085px] my-[28px] overflow-hidden"
  >
    <LazyAppNavbarCurrencyPicker @close="showCurrencyPicker = false" />
  </ApzModal>

  <ApzModal
    v-model="showLoginFormModal"
    :animated="false"
    class="!w-[475px] p-30 relative"
    @update:model-value="() => (showLoginForm = false)"
  >
    <LazyAuthForm
      type="login"
      mode="popup"
    />
  </ApzModal>
</template>

<style scoped lang="scss">
.overlay {
  @apply absolute inset-0 bg-black bg-opacity-50 z-50 rounded flex justify-center items-center;
}
</style>
